import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { motion } from "framer-motion"
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';

const Project = () => {

    const descriptionRef = useRef(null)
    const youtubeRef = useRef(null)
    const mapRef = useRef(null)
    const featuresRef = useRef(null)
    const [images, setİmages] = useState([])
    const { id } = useParams()
    const [data, setData] = useState([])
    const [plans, setPlans] = useState([])
    useEffect(() => {

        const formData = new FormData()
        formData.append('action', 'selectProjectbyId')
        formData.append('id', id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(res => {
                document.title = res.name
                setData(res)
                setPlans(res.plans.split(','))
                console.log(res)
                descriptionRef.current.innerHTML = res.description
                featuresRef.current.innerHTML = res.features
                res.konum.includes('https') ? mapRef.current.innerHTML = res.konum : mapRef.current.classList.add('hidden')
                res.youtube.includes('https') ? console.log(1) : youtubeRef.current.classList.add('hidden')
                res && res.images.includes(',') ? setİmages(res.images.split(',')) : setİmages(res.images)

            })
    }, [])




    return (
        <div className='w-full min-h-screen flex flex-col items-start justify-start'>
            <Navbar></Navbar>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='w-full h-[75vh]  '>
                <Swiper
                    effect={'fade'}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination, EffectFade]}
                    className='w-full h-screen bg-[#282828] border-white '
                >
                    {
                        typeof (images) != 'string' ?
                            images?.map((item, index) => {
                                return (
                                    <SwiperSlide className='relative h-screen w-full'>
                                        <motion.span
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 2 }}
                                            className='w-full h-full text-white text-5xl tracking-[10px] uppercase flex items-end pb-40  justify-end px-16  bg-black/40 absolute left-0 top-0' >
                                            <h1 className='lg:flex hidden items-center justify-center bg-black/60 p-4 rounded-sm'>
                                                {
                                                    data.name
                                                }
                                            </h1>
                                        </motion.span>
                                        <img alt={index} src={process.env.REACT_APP_BASE_URL + item} className='w-full object-cover h-screen bg-center  '></img>
                                    </SwiperSlide>
                                )
                            }) : <SwiperSlide className='relative h-screen w-full'>
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 2 }}
                                    className='w-full h-full text-white text-5xl tracking-[10px] uppercase flex items-end pb-40  justify-end px-16  bg-black/40 absolute left-0 top-0' >
                                    <h1 className='lg:flex hidden items-center justify-center bg-black/60 p-4 rounded-sm'>
                                        {
                                            data.name
                                        }
                                    </h1>
                                </motion.span>
                                <img alt={''} src={process.env.REACT_APP_BASE_URL + images} className='w-full object-cover h-screen bg-center  '></img>
                            </SwiperSlide>
                    }
                </Swiper>
            </motion.div>
            <div className='w-full h-auto flex flex-col gap-6 mt-40 lg:mt-40 items-center justify-center'>
                <h1 className='text-4xl lg:hidden text-black font-bold flex lg:p-3 p-6 mt-4 ' >
                    <h1 className='font-semibold uppercase tracking-widest'>
                        {
                            data[1]
                        }
                    </h1>
                </h1>
                <motion.div
                    initial={{ opacity: 0, x: -500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='w-full  h-full flex-col lg:flex-row flex items-start justify-center gap-6 mt-24 lg:mt-12 lg:px-20 px-12 '>

                    {plans && <img className='max-h-full w-full lg:w-1/2' src={process.env.REACT_APP_BASE_URL + plans[0]} />}

                    <div className=' lg:w-1/2 w-full min-h-full h-full flex flex-col items-start rounded-md justify-start  '>
                        <div className='w-full flex items-center justify-start p-4 border-b '>
                            <a className='text-3xl font-light tracking-[5px] '>Proje Özellikleri</a>

                        </div>
                        <div ref={descriptionRef} className=' w-full h-auto grid lg:grid-cols-2 gap-4 md:grid-cols-2 p-2 grid-cols-1'>

                        </div>
                    </div>
                </motion.div>
                <motion.div

                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className='w-full box h-auto lg:flex-row-reverse flex flex-col  items-start justify-between gap-12 mt-32 lg:mt-12 lg:px-20 px-12 '>
                    {
                        plans && <img className='max-h-full w-full lg:w-1/2' src={process.env.REACT_APP_BASE_URL + plans[1]} />
                    }
                    <div ref={featuresRef} className=' lg:w-1/2  w-full text-lg h-full  grid lg:grid-cols-2 gap-8 md:grid-cols-2 p-2 grid-cols-1 '>

                    </div>

                </motion.div>

               

                <div ref={mapRef} className='w-full map flex items-center justify-center px-12 py-12 mt-12 bg-slate-50'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d201864.4572177426!2d27.39829329909974!3d37.76349960027943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1731004182090!5m2!1str!2str"  referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div ref={youtubeRef} className='w-full p-12 mt-12 '>
                    <iframe
                        className='w-full h-[100vh] rounded-sm'
                        src={data.youtube} title="COZY Rainy Library with Fireplace | Videos made to study rather than sleep"
                    >

                    </iframe>
                </div>

            </div>
            <Footer></Footer>

        </div>
    )
}

export default Project