import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom'

const GalleryDetails = () => {
    const [data, setData] = useState([])
    const [images, setİmages] = useState([])
    const [plans, setPlans] = useState([])
    const container = useRef()
    const { id } = useParams()
    useEffect(() => {

        const formData = new FormData()
        formData.append('action', 'selectProjectbyId')
        formData.append('id', id)
        fetch(`${process.env.REACT_APP_BASE_URL}projects.php`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(data => {

                data.images.includes(',') ? setİmages(data.images.split(',')) : setİmages(data.images)
                setPlans(data?.plans.split(','))
                setData(data)



            })
    }, [])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const openModal = (src) => {
        setSelectedImage(src);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage("");
    };

    const ImageModal = ({ src, onClose }) => (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[99]">
            <span
                onClick={onClose}
                className="absolute top-5 z-[9999999999999999999999999999] right-5 text-white text-3xl cursor-pointer"
            >
                &times;
            </span>
            <img src={src} alt="Büyük Resim" className="w-3/4 h-3/4 rounded-lg shadow-lg" />
        </div>
    );


   

    return (
        <div ref={container} className='flex w-full items-start justify-start flex-col h-screen relative '>
            <Navbar></Navbar>   
            {isModalOpen && <ImageModal src={selectedImage} onClose={closeModal} />}
            <div className='w-full flex flex-col gap-2 items-start justify-start p-4'>
                <h1 className=' text-lg lg:text-3xl font-semibold uppercase  p-2'>Proje görselleri</h1>
                <div className='w-full flex flex-wrap lg:items-start lg:justify-start items-center justify-start gap-4'>
                    {
                        typeof (images) != 'string' ? images.map((item, index) => {
                            return (
                                <img onClick={(e) => { openModal(e.target.src) }} className=' ' src={process.env.REACT_APP_BASE_URL + item}></img>
                            )
                        }) : <img onClick={(e) => { openModal(e.target.src) }} className=' ' src={process.env.REACT_APP_BASE_URL + images}></img>
                    }
                </div>

            </div>

            <div className='w-full flex flex-col gap-2 items-start justify-start p-4'>
                <h1 className=' text-lg lg:text-3xl font-semibold uppercase  p-2'>Plan görselleri</h1>
                <div className='w-full flex flex-wrap lg:items-start lg:justify-start items-center justify-start gap-4'>
                    {
                        plans && plans.map((item, index) => {
                            return (
                                <img className=' 'onClick={(e) => { openModal(e.target.src) }} src={process.env.REACT_APP_BASE_URL + item}></img>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default GalleryDetails